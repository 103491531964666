<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 6C7.5 3.51472 9.51471 1.5 12 1.5C14.4853 1.5 16.5 3.51472 16.5 6C16.5 8.48528 14.4853 10.5 12 10.5C9.51471 10.5 7.5 8.48528 7.5 6Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75124 20.1053C3.82858 15.6156 7.49198 12 12 12C16.5081 12 20.1716 15.6157 20.2488 20.1056C20.2539 20.4034 20.0823 20.676 19.8117 20.8002C17.4327 21.8918 14.7865 22.5 12.0003 22.5C9.21386 22.5 6.56743 21.8917 4.18832 20.7999C3.91765 20.6757 3.74611 20.4031 3.75124 20.1053Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 3.25C10.4812 3.25 9.25 4.48122 9.25 6C9.25 7.51878 10.4812 8.75 12 8.75C13.5188 8.75 14.75 7.51878 14.75 6C14.75 4.48122 13.5188 3.25 12 3.25ZM7.24999 6C7.24999 3.37665 9.37664 1.25 12 1.25C14.6233 1.25 16.75 3.37665 16.75 6C16.75 8.62335 14.6233 10.75 12 10.75C9.37664 10.75 7.24999 8.62335 7.24999 6ZM5.54489 19.4818C7.5342 20.299 9.71347 20.75 12.0003 20.75C14.2869 20.75 16.466 20.2991 18.4551 19.482C18.0753 16.2543 15.33 13.75 12 13.75C8.67007 13.75 5.92483 16.2541 5.54489 19.4818ZM3.50128 20.101C3.58096 15.4751 7.35535 11.75 12 11.75C16.6447 11.75 20.4192 15.4753 20.4987 20.1014C20.5055 20.4984 20.2768 20.8618 19.9159 21.0274C17.5049 22.1337 14.8232 22.75 12.0003 22.75C9.17714 22.75 6.49521 22.1336 4.08405 21.0271C3.72315 20.8615 3.49444 20.498 3.50128 20.101Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconUser",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
